import React, { Fragment } from "react";
import { graphql } from '@apollo/client/react/hoc';
import { gql } from '@apollo/client'
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { noComma } from "../../common/utils";
import { decode } from 'he';
import {
  Hero,
  Loading,
  SectionFull,
  CardHighlight,
  Sidebar,
  MoreLink,
  NavIcon,
  variables,
} from "@website2018/da-dobsonville";

import Actions from '../../components/Actions/Actions';
import Asides from '../../components/Asides/Asides';
import Campaigns from '../../components/Campaigns/Campaigns';
import Champ from '../../components/Champ/Champ';
import Posts from '../../components/Newsroom/Posts';
import Spacing from '../../components/Spacing/Spacing';
import Videos from '../../components/Videos/Videos';
import NoSSR from '../../components/Nav/NoSSR';

const HOME_QUERY = gql`
  query GetHomePage {
    page(slug: "home") {
      id
      seo
      title {
        rendered
      }
      excerpt {
        rendered
      }
      featured_media {
        id
        source_url
        title {
          rendered
        }
      }
      asides {
        id
        title {
          rendered
        }
        content {
          rendered
        }
        aside_options
      }
      highlight {
        person {
          id
          slug
          title {
            rendered
          }
          excerpt {
            rendered
          }
          role
          facebook
          twitter
          featured_media {
            id
            source_url
            title {
              rendered
            }
            media_details {
              sizes {
                large { source_url }
              }
            }
          }
        }
      }
      hero {
        headline
        description
        cta_type
        button {
          link_url
          link_label
          link_target
          link_color
        }
        countdown {
          headline
          date
        }
        group_headline
        cta_group {
          title
          link_label
          link_url
          link_color
        }
        background_image {
          url
        }
        focus_point
      }
    }
    latest: campaigns(
      first: 5
      meta_key: "campaign_featured"
      meta_value: "featured"
      taxonomy: "province"
      term: "national"
    ) {
      id
      slug
      title {
        rendered
      }
      excerpt {
        rendered
      }
      featured_media {
        id
        source_url
        title {
          rendered
        }
        media_details {
            sizes {
              medium_large { source_url }
            }
        }
      }
      province {
        id
        name
      }
      external {
        status
        link
      }
    }
    videos(slug: "home") {
      id
      videoId
      title {
        rendered
      }
      excerpt {
        rendered
      }
      category {
        id
        name
      }
      featured_media {
        id
        source_url
        title {
          rendered
        }
      }
    }
    stickies: posts(
      sticky: true
      first: 1
      taxonomy: "category"
      term: "news"
    ) {
      id
      date
      slug
      formattedDate
      title {
        rendered
      }
      category {
        id
        name
      }
      featured_media {
        id
        source_url
        title {
          rendered
        }
        media_details {
          sizes {
            medium { source_url }
          }
        }
      }
    }
    posts(first: 9, taxonomy: "category", term: "news") {
      id
      date
      slug
      formattedDate
      title {
        rendered
      }
      category {
        id
        name
      }
      featured_media {
        id
        source_url
        title {
          rendered
        }
        media_details {
          sizes {
            medium { source_url }
          }
        }
      }
    }
  }
`;

const withData = graphql(HOME_QUERY, {
  props: ({ data }) => ({ ...data }),
});

class HomeContainer extends React.Component {
  state = {
    client: false,
  };

  render() {
    const {
      error,
      loading,
      page,
      posts,
      stickies,
      videos,
      latest,
    } = this.props;
    const { colors, spacing } = variables;
    if (loading) return <Loading />;
    if (error) return <h1>ERROR</h1>;
    const { page: p } = this.props;

    return (
      <Fragment>
        <Helmet
          defaultTitle={decode(p.seo.title !== "" ? p.seo.title : p.title.rendered)}
          meta={[
            {
              name: "description",
              content:
                p.seo.metadesc !== "" ? p.seo.metadesc : p.excerpt.rendered,
            },
            {
              name: "og:title",
              content:
                p.seo["opengraph-title"] !== ""
                  ? p.seo["opengraph-title"]
                  : p.title.rendered,
            },
            {
              name: "og:description",
              content:
                p.seo["opengraph-description"] !== ""
                  ? p.seo["opengraph-description"]
                  : p.excerpt.rendered,
            },
            { name: "og:url", content: `` },
            { name: "og:site_name", content: `Democratic Alliance` },
            { name: "og:type", content: `business` },
            { name: "og:image", content: p.seo["opengraph-image"] || null },
            { name: "fb:app_id", content: "1262049647255981" },
            { name: "twitter:card", content: "”summary_large_image”" },
            { name: "twitter:site", content: "@our_da" },
            {
              name: "twitter:title",
              content:
                p.seo["twitter-title"] !== ""
                  ? p.seo["twitter-title"]
                  : p.title.rendered,
            },
            {
              name: "twitter:description",
              content:
                p.seo["twitter-description"] !== ""
                  ? p.seo["twitter-description”"]
                  : p.excerpt.rendered,
            },
            {
              name: "twitter:image",
              content:
                p.seo["twitter-image"] !== "" ? p.seo["twitter-image"] : null,
            },
          ]}
          htmlAttributes={{ lang: "en" }}
        />
        <Hero
          center
          imageUrl={
            page.hero.background_image ? page.hero.background_image.url : null
          }
          bgPosition={
            page.hero && page.focus_point && noComma(page.hero.focus_point)
          }
          small={false}
          mb={"none"}
          bg={"none"}
          description={page.hero.description ? page.hero.description : null}
          title={page.hero.headline || page.title.rendered}
        >
          {page.hero.cta_type === "Group" ? (
            <Actions
              type={page.hero.cta_type}
              actions={page.hero.cta_group}
              headline={page.hero.group_headline}
            />
          ) : null}
          {page.hero.cta_type === "Countdown" ? (
            <Actions
              type={page.hero.cta_type}
              headline={page.hero.countdown.headline}
              date={page.hero.countdown.date}
              label={
                page.hero.button &&
                page.hero.button.link_label &&
                page.hero.button.link_label
              }
              color={
                page.hero.button &&
                page.hero.button.link_color &&
                page.hero.button.link_color
              }
              url={
                page.hero.button &&
                page.hero.button.link_url &&
                page.hero.button.link_url
              }
              target={
                page.hero.button &&
                page.hero.button.link_target &&
                page.hero.button.link_target
              }
            />
          ) : null}
          {page.hero.cta_type === "Button" ? (
            <Actions
              type={page.hero.cta_type}
              label={page.hero.button.link_label}
              color={page.hero.button.link_color}
              url={page.hero.button.link_url}
              target={page.hero.button.link_target}
            />
          ) : null}
        </Hero>
        <Champ formId={"newsletter"} />
        <SectionFull mt="medium" mb="large">
          <div className="col-lg-7">
            <Spacing bottom size="space3">
              <Campaigns
                campaigns={latest}
                title={"Latest Campaigns"}
                view={true}
              />
            </Spacing>
          </div>
          <Sidebar>
            <Asides asides={page.asides} />
          </Sidebar>
        </SectionFull>
        <NoSSR>
          <Videos videos={videos} title={"Latest Videos"} />
        </NoSSR>

        <Posts stickies={stickies} posts={posts} title={"Newsroom"} />
        <Champ formId={"straighttalk"} />
        {page.highlight.person ? (
          <SectionFull bgColor="light" mb="none" pt="large" pb="large">
            <div className="col-md-12">
              <CardHighlight
                imageUrl={
                  page.highlight.person.featured_media
                    ? (
                        (page.highlight.person.featured_media.media_details
                          && page.highlight.person.featured_media.media_details
                          && page.highlight.person.featured_media.media_details.sizes.large)
                        ? page.highlight.person.featured_media.media_details.sizes.large.source_url
                        : page.highlight.person.featured_media.source_url
                      )
                    : null
                }
                title={page.highlight.person.title.rendered}
                meta={page.highlight.person.role}
                paragraph={page.highlight.person.excerpt.rendered}
              >
                <Spacing bottom size={spacing.space2}>
                  <Link to={`/people/${page.highlight.person.slug}`}>
                    <MoreLink mb="medium">Read More</MoreLink>
                  </Link>
                </Spacing>
                {page.highlight.person.facebook && (
                  <NavIcon
                    iconType="bootstrap"
                    iconName="facebook"
                    iconColor={colors.socialFacebook}
                  >
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={page.highlight.person.facebook}
                      title={page.highlight.person.facebook}
                    >
                      {page.highlight.person.title.rendered} on Facebook
                    </a>
                  </NavIcon>
                )}
                {page.highlight.person.twitter && (
                  <NavIcon iconType="bootstrap" iconName="twitter-x" iconColor={colors.socialTwitter}>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`https://www.twitter.com/${page.highlight.person.twitter}`}
                      title={page.highlight.person.twitter}
                    >
                      Follow {page.highlight.person.twitter}
                    </a>
                  </NavIcon>
                )}
              </CardHighlight>
            </div>
          </SectionFull>
        ) : null}
      </Fragment>
    );
  }
}

export default withData(HomeContainer);